import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';

export const ContactUsButton = styled(BsButtonWithIcon)(
  ({ visible }: { visible: 'hidden' | 'visible' }) =>
    sx2CssThemeFn({
      marginTop: '15px',
      height: '50px',
      width: '50px',
      boxShadow: 'large',
      visibility: visible,
      backgroundColor: 'primary',
      ml: 'auto',
      '.icon': {
        variant: 'icons.xlg',
      },
    })
);
