import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { LazyHydrate } from '@hotelplan/components.common.lazy-render';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { useLogoRoute } from '@hotelplan/fdr.hook.use-logo-route';
import trackMenu, { MenuEventType } from '@hotelplan/fdr.lib.track.menu';
import { FdrHeaderLayout } from '@hotelplan/fdr.regular.fusion.fdr-header-layout';
import { FdrMobileNav } from '@hotelplan/fdr.regular.fusion.fdr-mobile-nav';
import { FdrShadowMenu } from '@hotelplan/fdr.regular.fusion.fdr-shadow-menu';
import { usePageTransitionJustStarted } from '@hotelplan/libs.hooks';
import { FdrMobileMenu } from 'fdr/components/local/fdr-mobile-menu';
import {
  FdrAccountSiteTool,
  FdrWishlistSiteTool,
} from 'fdr/components/local/fdr-site-tool-buttons';
import {
  BurgerButtonContainer,
  HeaderControls,
} from './fdr-header-mobile.styles';
import { useHeaderData } from './useHeaderData';

const HelpOverlayMobile = dynamic<{}>(
  () =>
    import(
      'fdr/components/local/fdr-help-overlay/fdr-help-overlay-mobile'
    ).then(module => module.FdrHelpOverlayMobile),
  {
    ssr: false,
  }
);

enum MenuModalTypes {
  MY_ACCOUNT = `my-account`,
  MAIN_MENU = 'main-menu',
}

function FdrHeaderMobile(): React.ReactElement {
  const { menus, logo } = useHeaderData();
  const logoRoute = useLogoRoute();
  const [activeMenu, setActiveMenu] = useState<MenuModalTypes | null>(null);
  const transitionStarted = usePageTransitionJustStarted();

  const handleOnClickBurger = isMenuOpen => {
    if (!isMenuOpen) {
      window.scrollTo(0, -1);
    }

    trackMenu({
      type: isMenuOpen ? MenuEventType.MENU_CLOSE : MenuEventType.MENU_OPEN,
    });

    setActiveMenu(prevMenu =>
      prevMenu === MenuModalTypes.MAIN_MENU ? null : MenuModalTypes.MAIN_MENU
    );
  };

  useEffect(() => {
    if (transitionStarted) {
      setActiveMenu(null);
    }
  }, [transitionStarted]);

  const isMainMenu = activeMenu === MenuModalTypes.MAIN_MENU;

  return (
    <FdrHeaderLayout logo={logo} logoRouterProps={logoRoute}>
      <>
        <HeaderControls>
          <FdrWishlistSiteTool />
          <FdrAccountSiteTool />
          <BurgerButtonContainer>
            <BsButtonWithIcon
              variant="burgerButton"
              icon={{
                name: isMainMenu ? 'close' : 'navi',
                size: isMainMenu ? 'lg' : 'xlg',
              }}
              onClick={() => {
                handleOnClickBurger(isMainMenu);
              }}
            />
          </BurgerButtonContainer>
          {isMainMenu ? (
            <FdrMobileNav>
              <FdrMobileMenu
                menus={menus}
                onClose={() => setActiveMenu(null)}
                showLanguageControl={true}
              />
            </FdrMobileNav>
          ) : null}
        </HeaderControls>
        <LazyHydrate whenIdle={true} noWrapper={true}>
          <HelpOverlayMobile />
        </LazyHydrate>
      </>
      <FdrShadowMenu menus={menus} />
    </FdrHeaderLayout>
  );
}

export default React.memo(FdrHeaderMobile);
