import styled from 'styled-components';

export const HeaderControls = styled.div({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
});

export const BurgerButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});
