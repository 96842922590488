import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { BsSwitch } from '@hotelplan/core.fusion.bs-switch';
import { BsLink } from '@hotelplan/core.basis.bs-link';

export const BackButton = styled(BsButtonWithIcon)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    color: 'black',
    display: 'flex',
    ...FONT_SIZE.LARGE,
    '.icon': {
      color: 'primary',
      mr: 2,
      transform: 'rotate(180deg)',
    },
    flexShrink: '0',
    width: '100%',
    px: 0,
    py: '10px',
  })
);

export const MenuWrapper = styled.ul({
  paddingTop: '24px',
});

export const MenuItemHeaderLink = styled(BsLink)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    py: '10px',
    mb: '-1px',
    width: '100%',
    ...FONT_SIZE.NAVIGATION,
    color: 'secondary',
  })
);

export const LanguageSwitchButton = styled(BsSwitch)(
  sx2CssThemeFn({
    ml: 'auto',
    width: '232px',
  })
);
