import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const MEDIA_LANDSCAPE_SCREEN =
  '@media screen and (max-width: 1000px) and (orientation: landscape)';

export const ContactsSection = styled.div(({ theme: { FONT_SIZE, radii } }) =>
  sx2CssThemeFn({
    width: '100%',
    bg: 'lightGreen',
    borderRadius: '4px',
    mb: 7,
    [MEDIA_LANDSCAPE_SCREEN]: {
      position: 'relative',
      mr: '120px',
      width: 'auto',
      marginBottom: '80px',
    },
    '.info-card': {
      backgroundColor: 'bgLightGrey',
      borderRadius: `${radii.default}!important`,
      '.help-overlay-contact-top': {
        gap: 2,
        pb: 3,
        '.help-overlay-contact-title': { display: 'none' },
        '.-image-text-secondary': {
          ...FONT_SIZE.MEDIUM,
          color: 'black',
        },
      },
      '.help-overlay-contact-bottom': {
        '.additional-info': {
          'p:nth-of-type(2)': {
            mt: 2,
          },
        },
      },
      '.schedule': {
        ...FONT_SIZE.SMALL,
        p: FONT_SIZE.SMALL,
        '.help-overlay-opening-hours-item': {
          pb: 1,
        },
      },
    },
  })
);

export const ContactUsWrapper = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    position: 'fixed',
    bottom: 4,
    left: 0,
    width: '100%',
    px: 4,
    [MEDIA_LANDSCAPE_SCREEN]: {
      position: 'relative',
      marginTop: '-140px',
    },
  })
);
