import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { ClickEventType, trackClicks } from '@hotelplan/fdr.lib.track.meta';
import { expandChat } from '@hotelplan/fdr.regular.fusion.fdr-chat';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { FdrChat } from 'fdr/components/local/fdr-chat';
import { OLARK_LOADING_REFRESH_INTERVAL } from './fdr-contact-us.constants';
import { ContactUsButton } from './fdr-contact-us.styles';

export const FdrContactUs = () => {
  const [t] = useTranslation('common');
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [olarkLoaded, setOlarkLoaded] = useState(false);

  useIsomorphicLayoutEffect(() => {
    let intervalId;

    if (!olarkLoaded) {
      // Sometimes olark script is not loaded properly before executing this effect, so we have to check it
      // continuously until window.olark is defined.
      intervalId = setInterval(() => {
        if (window?.olark) {
          window.olark(`api.box.onHide`, () => {
            setIsChatVisible(false);
          });
          window.olark(`api.box.onExpand`, function () {
            setIsChatVisible(true);
          });
          setOlarkLoaded(true);
        }
      }, OLARK_LOADING_REFRESH_INTERVAL);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [olarkLoaded]);

  return (
    <>
      <ContactUsButton
        variant="circleBtn"
        icon={{ name: 'message' }}
        visible={isChatVisible ? 'hidden' : 'visible'}
        onClick={() => {
          expandChat();
          trackClicks({
            type: ClickEventType.CHAT,
            payload: { source: 'olark' },
          });
        }}
      >
        {t('contactUs')}
      </ContactUsButton>
      <FdrChat />
    </>
  );
};
