import React from 'react';
import { FdrContactUs } from 'fdr/components/local/fdr-contact-us';
import {
  HelpOverlayBoxes,
  useFdrHelpOverlayData,
} from 'fdr/components/local/fdr-help-overlay';
import { FdrHelpOverlayBoxes } from 'fdr/components/local/fdr-help-overlay/fdr-help-overlay-boxes';
import { FdrMobileMenuContactsSkeleton } from './fdr-mobile-menu-contacts.skeleton';
import {
  ContactsSection,
  ContactUsWrapper,
} from './fdr-mobile-menu-contacts.styles';

export const FdrMobileMenuContacts: React.FC = () => {
  const { boxes, agency, loading, noAgencyId } = useFdrHelpOverlayData();

  if (loading) {
    return <FdrMobileMenuContactsSkeleton />;
  }

  return (
    <>
      <ContactsSection>
        <FdrHelpOverlayBoxes
          boxes={boxes}
          boxTypeWhitelist={[HelpOverlayBoxes.BoxContact]}
          agency={agency}
          noAgencyId={noAgencyId}
        />
      </ContactsSection>
      <ContactUsWrapper>
        <FdrContactUs />
      </ContactUsWrapper>
    </>
  );
};
