import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthentication } from '@hotelplan/fdr.lib.ident.with-auth';
import trackMenu, { MenuEventType } from '@hotelplan/fdr.lib.track.menu';
import {
  useOnChangeLanguageControl,
  useLanguageControl,
} from '@hotelplan/fdr.regular.basis.fdr-lang-control';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import {
  FdrMobileNavLink,
  FdrMobileNavMenu,
} from '@hotelplan/fdr.regular.fusion.fdr-mobile-nav';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { FdrLinkType, FdrMenu, FdrMenuItem } from '@hotelplan/supergraph-api';
import { FdrMobileMenuContacts } from './fdr-mobile-menu-contacts';
import {
  BackButton,
  LanguageSwitchButton,
  MenuItemHeaderLink,
  MenuWrapper,
} from './fdr-mobile-menu.styles';

interface IFdrMobileMenuProps {
  menus: FdrMenu[];
  showLanguageControl?: boolean;
  highlightActiveItem?: boolean;
  onClose?(): void;
}

export const FdrMobileMenu: React.FC<IFdrMobileMenuProps> = ({
  menus,
  onClose,
  showLanguageControl,
  highlightActiveItem,
}) => {
  const [t, { language }] = useTranslation(['common', 'myaccount']);
  const { channelType, logout } = useAuthentication();
  const { optionsList } = useLanguageControl();
  const [, changeLanguage] = useOnChangeLanguageControl({
    onChange: onClose,
  });

  const [currentSubMenu, setCurrentSubMenu] = useState<null | string>(null);

  let subMenuIndex = 0;

  const subMenu = menus?.find((link, i) => {
    if (link.caption !== currentSubMenu) return false;
    subMenuIndex = i;
    return true;
  });

  const logoutHandler = useCallback(async () => {
    await logout();
    window.location.pathname = '/';
  }, [logout]);

  const trackSubMenuClick = (link: FdrMenu, positionIndex: number) => {
    trackMenu({
      type: MenuEventType.MENU_ACTION,
      payload: {
        link: link.link?.url || '',
        position: positionIndex,
      },
    });
  };

  const handleMenuClick = (
    e: React.MouseEvent<HTMLElement>,
    link: FdrMenu | FdrMenuItem,
    i: number,
    showSubMenu?: React.Dispatch<React.SetStateAction<string | null>>
  ) => {
    if (link.__typename === 'FdrMenu' && link?.menuItems?.length) {
      e.preventDefault();
      trackMenu({
        type: MenuEventType.MENU_EXPAND,
        payload: { label: link.caption, position: i },
      });
      showSubMenu?.(link.caption);
    } else if (link.__typename === 'FdrMenuItem') {
      trackMenu({
        type: MenuEventType.MENU_ACTION,
        payload: {
          link: link.link?.url || '',
          position: i,
        },
      });
    }
  };

  const handleSubMenuClick = (
    link: FdrMenu | FdrMenuItem,
    positionIndex: number,
    i: number
  ) => {
    trackMenu({
      type: MenuEventType.MENU_ACTION,
      payload: {
        link: link.link?.url || '',
        position: [positionIndex, i],
      },
    });
    onClose?.();
  };

  return (
    <>
      {showLanguageControl && !currentSubMenu && optionsList.length && (
        <LanguageSwitchButton
          className="language-control"
          testId="languageMobileSwitcher"
          name="languageControl"
          valueFirst={optionsList[0].value}
          valueSecond={optionsList[1].value}
          checkedValue={language}
          labelFirst={optionsList[0].label}
          labelSecond={optionsList[1].label}
          onCheck={changeLanguage}
        />
      )}
      {subMenu && (
        <>
          <BackButton
            variant={E_BUTTON_TYPE.ICON_BUTTON}
            icon={{ name: 'chevron-right' }}
            onClick={() => {
              trackMenu({
                type: MenuEventType.MENU_COLLAPSE,
                payload: { label: subMenu.caption, position: subMenuIndex },
              });
              trackMenu({
                type: MenuEventType.MENU_COLLAPSE,
                payload: { position: subMenuIndex, label: subMenu.caption },
              });
              setCurrentSubMenu(null);
            }}
          >
            {t('back.button')}
          </BackButton>
          <MenuWrapper>
            <li>
              <FdrRoute link={subMenu.link}>
                <MenuItemHeaderLink
                  onClick={() => {
                    trackSubMenuClick(subMenu, subMenuIndex);
                  }}
                >
                  {subMenu.caption}
                </MenuItemHeaderLink>
              </FdrRoute>
            </li>
          </MenuWrapper>
        </>
      )}
      <FdrMobileNavMenu
        menus={subMenu ? subMenu?.menuItems || [] : menus}
        onClick={(
          e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
          link: FdrMenu,
          i: number
        ) => {
          subMenu
            ? handleSubMenuClick(subMenu, subMenuIndex, i)
            : handleMenuClick(e, link, i, setCurrentSubMenu);
        }}
        highlightActiveItem={subMenu ? false : !!highlightActiveItem}
        icon="chevron-right"
      >
        {!subMenu && channelType !== AuthChannelType.B2CAnonymous ? (
          <ul>
            <FdrMobileNavLink
              item={{
                caption: t('myaccount:logout'),
                link: {
                  url: '#',
                  type: FdrLinkType.Internal,
                },
              }}
              id="logout"
              onClick={logoutHandler}
              icon="logout"
              showIcon={true}
            />
          </ul>
        ) : null}
      </FdrMobileNavMenu>
      {!currentSubMenu && <FdrMobileMenuContacts />}
    </>
  );
};
