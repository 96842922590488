import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';

export const FdrMobileMenuContactsSkeleton: React.FC = () => {
  const H = 16;
  const P = 24;

  return (
    <PlaceholderLoader
      height={400}
      width={300}
      uid="navMenuSkeleton"
      style={{ height: '400px', width: `100%` }}
    >
      {Array.from({ length: 4 }).map((_, i) => (
        <rect
          key={i}
          x={8}
          y={(H + P) * i}
          rx="5"
          ry="5"
          width={i % 2 ? 165 : 85}
          height={H}
        />
      ))}
    </PlaceholderLoader>
  );
};
